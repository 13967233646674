import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import PartialNumberStarRating from "housecall-ui/dist/core/Reviews/PartialNumberStarRating.js"
import React from "react"
import { getOnlineBookingBaseUrl } from "../utils/environmentUrls"
import ShowPhone from "./ShowPhone"

const styles = theme => ({
  container: {
    height: "100%"
  },
  button: {
    height: 25,
    fontSize: 12
  },
  topButton: {
    height: 25,
    fontSize: 12,
    marginBottom: 10
  },
  hrefStyles: {
    textDecoration: "none"
  },
  logo: {
    height: 90,
    marginBottom: 0
  },
  rowCard: {
    height: 140,
    marginBottom: 15
  },
  gridBorder: {
    borderRight: `1px solid ${theme.palette.blueGrayLight}`,
    borderLeft: `1px solid ${theme.palette.blueGrayLight}`,
    paddingLeft: 20,
    paddingRight: 20
  },
  orgMargin: {
    marginLeft: 20,
    marginTop: 10
  },
  ratingMargin: {
    marginLeft: 15
  },
  phoneButton: {
    marginLeft: 10
  }
})

function NominatedTable({ classes, rows }) {

  function buildOnlineBookingButton(classes, row) {
    if (row.online_booking) {
      return (
        <a
          href={`${getOnlineBookingBaseUrl()}book/pro/${row.online_booking}?attribution=leaderboard`}
          className={classes.hrefStyles}
          target="_blank"
          rel="nofollow"
        >
          {buildButton(!row.online_booking, classes.topButton, "Book Online")}
        </a>
      )
    } else {
      return (
        buildButton(!row.online_booking, classes.topButton, "Book Online")
      )
    }
  }

  function buildWebsiteButton(classes, row) {
    if (row.website) {
      return (
        <a
          href={`http://${row.website}`}
          className={classes.hrefStyles}
          target="_blank"
          rel="nofollow"
        >
          {buildButton(!row.website, classes.button, "Visit Website")}
        </a>
      )
    } else {
      return (
        buildButton(!row.website, classes.button, "Visit Website")
      )
    }
  }

  function buildButton(disabled, className, copy) {
    return (
      <Button variant="outlined" color="primary" size="small" disabled={disabled}
              className={className}>
        {copy}
      </Button>
    )
  }

  return (
    <Grid container justify="center">
      <Grid item xs={11} sm={8}>
        {rows && rows.map((row, index) => (
          <Paper className={classes.rowCard} key={index}>
            <Grid container alignItems="center" className={classes.container}>
              <Grid item xs={2} align="center">
                <Typography variant="h3" align="center">{index + 1}</Typography>
                <Typography variant="caption" align="center"><b>Total
                  Votes: {row.count}</b></Typography>
              </Grid>
              <Grid item xs={3} align="center" className={classes.gridBorder}>
                <img src={row.logo} className={classes.logo} />
              </Grid>
              <Grid item xs={7} align="center">
                <Grid container justify="space-around" alignItems="center">
                  <Grid item xs={7}>
                    <Grid item xs={12} align="left" className={classes.orgMargin}>
                      <Typography variant="body2">
                        <b>{row.organizationName}</b>
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} align="left" className={classes.ratingMargin}>
                      <PartialNumberStarRating rating={row.rating || 0} size={20} />
                      <Typography
                        variant="body2">{row.rating} ({row.total_ratings || 0} reviews)</Typography>
                    </Grid>
                    <Grid item xs={12} align="left" className={classes.phoneButton}>
                      <ShowPhone phone={row.phone} />
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        {buildOnlineBookingButton(classes, row)}
                      </Grid>
                      <Grid item xs={12}>
                        {buildWebsiteButton(classes, row)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(NominatedTable)
