import { is_present } from '../Common/objectHelper.js'
import { getNominationsClientBaseUrl } from '../utils/environmentUrls.js';

const parseUrlParams = () => {
  const search = window.location.search.substring(1);

  return is_present(search) ? JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {}
}

export const getOrgName = () => {
  const urlParams = parseUrlParams()
  return urlParams.org_name || ''
}

const getInvitationUuid = () => {
  const urlParams = parseUrlParams()
  return urlParams.invitation_uuid || ''
}

const getUtms = () => {
  const urlParams = parseUrlParams()
  const utmsArray = []
  Object.entries(urlParams).forEach(([key,value]) => {
    key.includes('utm') && utmsArray.push(`${key}=${value}`)
  })
  return utmsArray.join('&')
}

export const getAcceptNominationUrl = () => {
  const baseUrl = getNominationsClientBaseUrl()
  const invitationUuid = getInvitationUuid()
  const utms = getUtms()
  return `${baseUrl}/nomination/${invitationUuid}?${utms}`
}
