import React from "react"
import top10 from "./top-pros-02.svg"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({
  badge: {
    height: 200,
    width: 200,
    position: "relative",
    marginTop: 60
  },
  year: {
    position: 'absolute',
    top: 125,
    left: 35
  },
  name: {
    position: 'absolute',
    top: 22,
    left: 12,
    width: 176
  }
})

function Top10Badge({ classes}) {
  return (
    <div className={classes.badge} >
      <img src={top10}  />
      <Typography color="secondary" variant="body1" className={classes.year}>
        <b>{new Date().getFullYear()}</b>
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Top10Badge)
