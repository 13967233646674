import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { getOnlineBookingBaseUrl } from "../utils/environmentUrls"
import ShowPhone from "./ShowPhone"

const styles = theme => ({
  wrapper: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    backgroundColor: theme.palette.grayLight,
    padding: 16
  },
  table: {
    minWidth: 700
  },
  padding: {
    marginTop: 10,
    marginBottom: 30
  },
  tableRow: {
    backgroundColor: theme.palette.grayLight,
    color: theme.palette.blueGrayMedium
  },
  logo: {
    maxHeight: 80,
    marginBottom: 0
  },
  marginBottom: {
    marginBottom: 20
  },
  noMaxWidth: {
    maxWidth: 600
  }
})

function NominatedCardTable({ classes, rows }) {
  return (
    <div>
      {rows && rows.map((row, index) => (
        <Grid container key={index} className={classes.wrapper}>
          <Grid item xs={12} className={classes.marginBottom}>
            <Typography variant="h3">{row.organization_name}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.marginBottom}>
            <div style={{ padding: 4 }}>
              <Grid container justify="space-between" spacing={8}>
                <Grid item xs={9}>
                  {row.logo && <img src={row.logo} className={classes.logo} />} <br />
                  <ShowPhone phone={row.phone} logo />
                </Grid>
                <Grid item xs={3}>
                  <Typography>Current Rank</Typography>
                  <Typography color="textPrimary" variant="body1">{index + 1}</Typography>
                  <Typography>Total Votes</Typography>
                  <Typography color="textPrimary" variant="body1">{row.nominations}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} align="center">
            <Button variant="contained" color="primary"
                    href={`${getOnlineBookingBaseUrl()}book/pro${row.online_booking}?attribution=leaderboard`}
                    disabled={!row.online_booking} fullWidth className={classes.noMaxWidth}>
              Book Online
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.padding} align="center">
            <Button variant="outlined" color="primary" href={`http://${row.website}`}
                    disabled={!row.website} fullWidth className={classes.noMaxWidth}>
              Visit Website
            </Button>
          </Grid>
        </Grid>

      ))}
    </div>
  )
}

export default withStyles(styles)(NominatedCardTable)
