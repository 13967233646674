import Grid from "@material-ui/core/Grid/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { titleCase } from '../Common/string_utils.js'
import { Link } from 'gatsby'

const styles = theme => ({
  container: {
    marginBottom: 20
  },
  link: {
    color: theme.palette.bluePrimary
  },
  separator: {
    marginLeft: 5,
    marginRight: 5
  }
})

function Breadcrumbs({ classes, industry, state, city }) {

  function buildSeparator() {
    return (
      <span className={classes.separator}>></span>
    )
  }

  function buildIndividualBreadcrumb(text, link, separator) {
    return (
      <React.Fragment>
        <Link className={classes.link} to={link}>
          {text}
        </Link>
        {separator ? buildSeparator() : null}
      </React.Fragment>
    )
  }

  function buildFullBreadcrumbs() {
    let breadcrumbs = [buildIndividualBreadcrumb("All Industries", '/', true)]

    if (industry && state && city) {
      breadcrumbs.push(buildIndividualBreadcrumb(industry, `/${industry.toLowerCase()}`, true))
      breadcrumbs.push(buildIndividualBreadcrumb(state, `/${industry.toLowerCase()}/${state.toLowerCase()}`, true))
      breadcrumbs.push(titleCase(city))
    } else if (industry && state) {
      breadcrumbs.push(buildIndividualBreadcrumb(industry, `/${industry.toLowerCase()}`, true))
      breadcrumbs.push(titleCase(state))
    } else {
      breadcrumbs.push(titleCase(industry))
    }

    return breadcrumbs
  }

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item xs={11} sm={8}>
        <Typography variant="caption">
          {buildFullBreadcrumbs()}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Breadcrumbs)
