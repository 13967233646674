import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import withWidth from "@material-ui/core/withWidth"
import React from "react"
import { titleCase } from '../Common/string_utils.js'
import Breadcrumbs from '../components/Breadcrumbs.jsx'
import Layout from "../components/layout"
import NominatedCardTable from "../components/NominatedCardTable"
import NominatedTable from "../components/NominatedTable"
import stars from "../images/stars.svg"
import Top10Badge from "../images/Top10Badge"
import NominatedPro from "../components/NominatedPro.jsx";
import HousecallThemeProvider from 'housecall-ui/dist/DesignSystem/Styles/HousecallThemeProvider.js'

const styles = theme => ({
  heroGradient: {
    background: `linear-gradient(#165B88, #041C2F);`
  },
  subtitle: {
    background: theme.palette.grayLight,
    marginBottom: 15,
    paddingTop: 15
  },
  image: {
    marginBottom: 0
  },
  h1: {
    marginTop: 20,
    letterSpacing: 2,
    marginBottom: 20
  },
  breadCrumbs: {
    marginTop: 20
  },
  invitationSpacer: {
    height: 20
  }
})

function IndexPage({ pageContext, classes, location }) {

  function buildBreadcrumbs() {
    if (pageContext.industry) {
      return (
        <Breadcrumbs industry={pageContext.industry} state={pageContext.state}
                     city={pageContext.city} />
      )
    }
  }

  const showProInvitation = location.search.includes('invitation_uuid')

  return (
    <Layout>
      <div className={classes.heroGradient}>
        <Grid container justify="center">
          <Grid item xs={9} sm={7} align="center">
            <Top10Badge />
            <Typography variant={"h1"} color="secondary" align="center" className={classes.h1}>
              <b>{`The best ${pageContext.industry ? pageContext.industry.toLowerCase() : "service"} companies
          in ${determineLocation(pageContext)}`}</b>
            </Typography>
          </Grid>
        </Grid>
        {showProInvitation && <div className={classes.invitationSpacer}></div>}
      </div>
      {showProInvitation ?
        <HousecallThemeProvider>
          <NominatedPro pro={location.search} />
        </HousecallThemeProvider>
      :
        <div className={classes.subtitle}>
          <div style={{ padding: 4 }}>
            <Grid container justify="center" spacing={8}>
              <Grid item>
                <img src={stars} className={classes.image} />
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  <b>
                    Votes are counted based on Customer reviews and location
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      }
      <div className={classes.breadCrumbs}>
        {buildBreadcrumbs()}
      </div>
      <Hidden xsDown>
        <NominatedTable rows={pageContext.organizationData} />
      </Hidden>
      <Hidden smUp>
        <NominatedCardTable rows={pageContext.organizationData} />
      </Hidden>
    </Layout>
  )
}

export default withStyles(styles)(withWidth()(IndexPage))

function determineLocation(pageContext) {
  if (pageContext.city) {
    return titleCase(pageContext.city)
  } else if (pageContext.state) {
    return titleCase(pageContext.state)
  } else {
    return "the United States"
  }
}
