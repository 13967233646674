export function is_blank(object) {
  if (object === false) {
    return false
  } else {
    return (
      (isPlainObject(object) && isEmptyObject(object)) ||
      (isArray(object) && object.length === 0) ||
      (typeof object === 'string' && trim(object) === '') ||
      (!object && object !== 0)
    )
  }
}

export function is_present(object) {
  return !is_blank(object)
}

function isPlainObject(obj) {
  let proto, Ctor
  const toString = ({}).toString

  if (!obj || toString.call(obj) !== "[object Object]") {
    return false
  }
  let getProto = Object.getPrototypeOf

  proto = getProto(obj)

  if (!proto) {
    return true
  }
  let class2type = {}
  let hasOwn = class2type.hasOwnProperty
  let fnToString = hasOwn.toString
  let ObjectFunctionString = fnToString.call(Object)

  Ctor = hasOwn.call(proto, "constructor") && proto.constructor
  return typeof Ctor === "function" && fnToString.call(Ctor) === ObjectFunctionString
}

function isEmptyObject(obj) {
  let name
  for (name in obj) {
    return false
  }
  return true
}

function isArray(obj) {
  return Array.isArray(obj)
}

function trim( text ) {
  let rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;

  return text == null ?
    "" :
    ( text + "" ).replace( rtrim, "" );
}
