import React, { useEffect } from 'react'
import withStyles from 'housecall-ui/dist/DesignSystem/Styles/withStyles'
import Paper from 'housecall-ui/dist/DesignSystem/Surfaces/Paper/Paper.js'
import Typography from 'housecall-ui/dist/DesignSystem/DataDisplay/Typography/Typography.js'
import Button from 'housecall-ui/dist/library/Buttons/Button.js'
import Grid from 'housecall-ui/dist/library/Layout/Grid.js'
import { getOrgName, getAcceptNominationUrl } from './nominatedProHelper.js'
import { trackButtonClicked } from '../Analytics/trackEventsService.js'
import { trackPage } from '../Analytics/analyticsService.js'

const styles = {
  padding: {
    padding: 24
  },
  button: {
    marginTop: 8,
    width: '100%',
    maxWidth: 400
  },
  moveUp: {
    marginTop: -20
  }
}

const  NominatedPro = ({ classes }) => {
  const orgName = getOrgName()
  const acceptUrl = getAcceptNominationUrl()

  useEffect(() => {
    trackPage('nomination landing page')
  }, [])

  return (
    <Grid container justify="center">
      <Grid item xs={11} md={8} className={classes.moveUp}>
        <Paper elevation={4} className={classes.padding}>
          <Typography variant="h2" gutterBottom>{orgName}</Typography>
          <Typography gutterBottom>
            Please confirm your business contact information so that customers can contact you or book you for jobs.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              trackButtonClicked('accept nomination').then(() => {
                window.location.href = acceptUrl
              })
            }}
          >
            Accept Nomination
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(NominatedPro)
