import React, { PureComponent, Fragment } from "react"
import Button from "@material-ui/core/Button"
import withStyles from "@material-ui/core/styles/withStyles"
import PhoneInTalk from "@material-ui/icons/PhoneInTalk"

const styles = theme => ({
  hrefStyles: {
    textDecoration: "none",
    color: theme.palette.bluePrimary,
  },
  logoStyles: {
    marginRight: 10,
    fill: theme.palette.bluePrimary,
  },
  logoWithoutPhoneStyles: {
    marginRight: 10,
    fill: 'light grey'
  },
  container: {
    marginLeft: 10
  },
  matchStyles: {
    marginLeft: 10,
    marginTop: 20,
    height: 35
  },
  button: {
    font: 'open sans'
  }
})

class ShowPhone extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      revealPhone: false,
    }
  }

  changeState() {
    this.setState({
      revealPhone: true,
    })
  }

  determinePhone() {
    if (this.props.phone) {
      return (
        <div className={this.props.classes.matchStyles}>
        <a href={`tel:${this.props.phone}`}
           className={this.props.classes.hrefStyles}>{this.formatPhoneNumber(this.props.phone)}</a>
        </div>
      )
    }
    return (
      "N/A"
    )
  }

  formatPhoneNumber(phoneNumberString) {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "")
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3]
    }
    return null
  }

  render() {
    return (
      <Fragment>
        {this.state.revealPhone ? this.determinePhone() : <Button variant="text" color="primary"
                                                                  className={this.props.classes.button}
                                                                  disabled={!this.props.phone}
                                                                 onClick={() => this.changeState()}>
        {<PhoneInTalk className={this.props.phone ? this.props.classes.logoStyles : this.props.classes.logoWithoutPhoneStyles} />} Reveal
      </Button>}
      </Fragment>
    )
  }
}

export default withStyles(styles)(ShowPhone)
